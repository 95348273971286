export const TIME_OUT_TRY_AGAIN = 'Timeout.Try again.';
export const WEB_AUTHN_NOT_PRESENTED =
  'Error: WebAuthn APIs are not present on this device';
export const BACK_TO_LOGIN = 'Back to Sign-in';
export const BACK = 'Back';
export const EMAIL_IS_NOT_VALID = 'Email is not valid.';
export const EMAIL_IS_REQUIRED = 'Please enter your Email.';
export const RECAPTHCA_IS_REQUIRED = 'Please confirm that you are not a robot.';
export const EMAIL = 'Email';
export const VERIFY = 'Verify';
export const RESEND_CODE = 'Resend Code';
export const RESEND_SMS = 'Resend sms';
export const ERROR = 'Error';
export const WARNING = 'Warning';
export const TIME_OUT = 'Timeout';
export const NEXT = 'Next';
export const SIGN_IN = 'Sign in';
export const LOGIN_ID_MELON_ACCOUNT = (sp) => `Sign in to ${SP_NAME(sp)}`;
export const RESEND = 'Resend';
export const SECURITY_CHECK = 'Security check';
export const ENTER_VERIFICATION_CODE = 'Verification Code';
export const VERIFICATION_HINT_MESSAGE_RECOVERY =
  'Enter the verification code that we have sent to:';
export const VERIFY_CODE = 'Verification Code';
export const VERIFY_CODE_NAME_IS_REQUIRED = 'Verification code is required.';
export const CHECK_SPAM =
  "Didn't receive the email? Please, check your junk folder.";
export const SEND_EMAIL = 'Send email';
export const SELECT_EMAIL_OR_SMS =
  'How would you like to receive your verification code?';
export const SUCCESS = 'Success';
export const SEND = 'Send';
export const SIGN_IN_OPTIONS = 'Sign-in Options';
export const ERROR_CODE_INVALID = 'Please enter valid code.';
export const SEND_VERIFICATION_CODE =
  'IDmelon will send a verification code to';
export const LOGIN_PAGE = {
  LOGIN_WITH_SECURITY_KEY: 'Sign in with a Passkey',
  LOGIN_WITH_IDMELON_AUTHENTICATOR: 'Sign in with IDmelon Authenticator',
  LOGIN_WITH_CODE: 'Code',
  METHOD: 'Method',
  METHOD_REQUIRED: 'Method is required',
  LOGIN_OPTION: 'Sign-in Options',
  CHOOSE_TO_LOGIN: 'Choose to Sign in',
  SIGN_IN_WITH_SECURITY_KEY: 'Sign in with a Passkey',
  SIGN_IN_OPTION: 'Sign-in options',
  OTHER_WAYS_OPTIONS: 'Other ways to Sign in',
  NO_SECURITY_KEY: 'No Passkey? ',
  SECURITY_KEY_TEXT: `your device will open a security window. Follow the instructions there to sign in.`,
  SECURITY_KEY_ERROR: `We couldn't verify you or the key you used. If you are using a security key, make sure this is your key and try again. If you don't have access to your security key, try other sign-in methods!`,
};
export const TRY_AGAIN = 'Try Again';
export const VERIFYING = 'Verifying';
export const SEND_SMS = 'Send SMS';
export const REGISTER_BTN_MSG = 'Sign up!';
export const REGISTER_MSG = 'No Account? ';
export const LOGIN_IDMELON_PANEL = 'Sign in to IDmelon Panel';
export const HEADER_PAGES = (sp) =>
  `IDmelon Login${SP_NAME(sp) !== 'IDmelon' ? ` - ${SP_NAME(sp)}` : ''}`;
export const SP_NAME = (sp) => {
  switch (sp) {
    case 'google':
      return 'Google';
    case 'office':
      return 'Office';
    case 'slack':
      return 'Slack';
    case 'idmelon':
      return 'IDmelon';
    case 'zendesk':
      return 'Zendesk';
    case 'tableau':
      return 'Tableau';
    case 'duo':
      return 'Duo';
    case 'pingidentity':
      return 'Ping Identity';
    case 'okta':
      return 'Okta';
    default:
      return 'IDmelon Panel';
  }
};
export const CONTINUE = 'Continue';
